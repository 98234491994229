/*
 * @Description:
 * @Author: chenz-l
 * @Date: 2024-05-14 13:24:02
 * @LastEditors: chenzhen
 */

// 权限列表
export const AUTHORITY_LIST = {
  // 管理员页面
  ADMIN_GLYYM: '57f50b6cf4ae1e6c5db72eb1e39d6e42',
  ADMIN_JSGL: '3260b403cdcc18e23f0fe2e5282a8691',
  ADMIN_YHGL: 'b8da92c7e584ee71e94e9adfc9d3a16b',
  ADMIN_JSLB: 'cd8f762839d6a4e4af9657c2c639c01f',
  ADMIN_JSTJ: '881d94387ad39bb380e0411737f757b1',
  ADMIN_JSBJ: 'cd010431deaeada371c26dcd2a7d9398',
  ADMIN_JSSC: 'fe7b1d7c18152ba3b8ca572e930fac46',
  ADMIN_YHLB: 'b152ec37f73c01b683a19f197e1d3ace',
  ADMIN_YHTJ: '0e9b08b0a8dc6f8cddee89b4a2e8fad6',
  ADMIN_YHBJ: 'bf1c2ae526a70376c109f7afbb708035',
  // ADMIN_YHSC: '68032a015b0f2912837a54cd23de0695',  // 用户删除
  ADMIN_YHQY: '687cf747971b4a81083a6502339c7d85',
  ADMIN_YHTY: '0289d025988132f9355f0b8db322179e',
  ADMIN_YHCZMM: 'ad1355e1daa64c33c6eb0a75f35eb083',
  ADMIN_JSXQ: 'e7624ce00021eeff8a6d20b8ac455fff',
  // ADMIN_YHXQ: '30545626fe23d67cf9f3c23e1ac4acbe',  // 用户详情

  // 个人中心
  USER_GRXXXG: 'aeb24dbcf23ba77dc2dfcbeda176763b',
  USER_GRZX: '4c9c01c18ee37d7f0d439a317b3c58e5',
  USER_XGMM: '794d632227fbc3a5a9f39bfbeff5c5ea',
  // 驾驶舱
  SXSQK: 'dd0d6fe98b80b43f32a6435aabee4a22',
  SXSQK_LBCX: 'c569d783c95b851910d68b43eda4d8de',
  SXSQK_DC: 'c61eca0013a9f877db94e906a763a414',
  // 各地市情况
  GDSQK: 'e3c480aa0e51307b602995f2548087ff',
  // 保险机构概览
  BXJGGL: 'ebe33ab8b9e4767518d5797d4a63aa44',
  // 统计分析
  TJFX: '53417743508ca4762730db3c6fa4201b',
  // 汇总统计表
  TJFX_HZTJB: '70b5b84aeacaae51922de0edffd0dda8',
  TJFX_HZTJB_YJWTHZB: '54b6c7baa2babfd6e6d2ec3d6e7e3e74',
  TJFX_HZTJB_YJWTHZB_LBCX: '29b987977508da47e41953801fc03837',
  TJFX_HZTJB_YJWTHZB_DC: '727653c19c22099930a44299a303aa9c',
  TJFX_HZTJB_SGYFFWHZB: '817ba7033733bc0998b6eb1cf0a707fe',
  TJFX_HZTJB_SGYFFWHZB_LBCX: 'df700a042ae08356a8494857782647a4',
  TJFX_HZTJB_SGYFFWHZB_DC: '88c4e44f79a3f8691e66f6e921fbbaf4',
  TJFX_HZTJB_TBQKHZB: 'a6a2cfd59dea8a7f721c016ef451a47c',
  TJFX_HZTJB_TBQKHZB_LBCX: 'ec5146a2f96c2f92db74bf2e452057fb',
  TJFX_HZTJB_TBQKHZB_DC: '37d0dbde89664ab00e59658d580766f8',
  TJFX_HZTJB_LPQKHZB: '6a30248e329683d4677e31dda873a11d',
  TJFX_HZTJB_LPQKHZB_LBCX: '42d24e63847d76b6ab676e846025715b',
  TJFX_HZTJB_LPQKHZB_DC: 'b4838c270d3fac392e9a2d1b9ab3a21e',
  TJFX_HZTJB_SBHZB: 'af77ee1235055cf6a85a9740520dd8e6',
  TJFX_HZTJB_SBHZB_LBCX: '957475ef0c8c306b80deaeabcc5c5647',
  TJFX_HZTJB_SBHZB_DC: '8dfe3ebd06512fcb6f820e72aaaa53b2',
  // TJFX_HZTJB_LBCX: '55f7ffbf2a5d5518fadd2615fe9785ec',
  // TJFX_HZTJB_DC: '4df7645c452ba9ac2bfcb8fe629dfd98',
  // 保单明细表
  TJFX_BDMXB: 'a71cf6e89dd546815ec952fb00b8401d',
  TJFX_BDMXB_LBCX: '289f4acf2ef0c3b36d94171ba8328986',
  TJFX_BDMXB_DC: '3f11a062752ea8cc20ccea470d8d6794',
  // 理赔明细表
  TJFX_LPMXB: 'f0d6e71c4ca20243aa1e0406900cd414',
  TJFX_LPMXB_LBCX: '6e025239888d9837f14590bd564e9da3',
  TJFX_LPMXB_DC: 'cfab1b43083eceb88c284dc883056cf0',
  // 事故预防服务明细表
  TJFX_SGYFMXB: '73c040775873a537b0de3a0aad261419',
  TJFX_SGYFMXB_LBCX: '293c4c56118bdc9970760c9e6bf231fd',
  TJFX_SGYFMXB_DC: 'fc50e06cfaeb7a86b44897cd3891122a',
  // 安全服务机构
  TJFX_AQFWJG: 'f52432bbd8afd74b1ad15848c6b7dc1d',
  TJFX_AQFWJG_LBCX: '7dece892a8ba58080fa7677877b2ba0c',
  TJFX_AQFWJG_DC: 'a8ad66afcb8c7aa34b93e4b945b65098',
  // 申报明细表
  TJFX_SBMXB: '64a123f2de3f2678bf06363b24a8c1ac',
  TJFX_SBMXB_LBCX: '2b7b1ed3a04181f3640e4e12cb0fe12f',
  TJFX_SBMXB_DC: '81d05c835b04595848e1f788b8dbadc3',
  // 运行报告
  YXBG: '76a7847b4931c271f73757848608df00',
  // 评优企业
  PYQY: '751b6709d2e0ca84f4ec1f06ed807c3b',
  // 资料上传
  ZLSC: '6fc923cd0ef06d6c699cd1a75ab363a9',
  // 备案
  BA: '69065a7314ed369731c3e892facc9feb',
  // 函件往来
  HJWL: '01d0e318c31ae0e23f8b8594eb3d7f1b',
};

// 图片
export const IMAGE_LIST = {
  simpleverifyImage: `${process.env.REACT_APP_STATIC_BASE_URL}/login/simpleverify.png`,
  azxQRcode: `${process.env.REACT_APP_STATIC_BASE_URL}/login/azxQRcode.jpg`,
  loginbg: `${process.env.REACT_APP_STATIC_BASE_URL}/login/loginbg.png`,
  dapingbg: `${process.env.REACT_APP_STATIC_BASE_URL}/home/daping-bg.jpg`,
  headerbg: `${process.env.REACT_APP_STATIC_BASE_URL}/home/headerbg.png`,
  navMenusActiveBg: `${process.env.REACT_APP_STATIC_BASE_URL}/home/nav-menus-active-bg.png`,
  navMenusBg: `${process.env.REACT_APP_STATIC_BASE_URL}/home/nav-menus-bg.png`,
  screeningProvinces: `${process.env.REACT_APP_STATIC_BASE_URL}/home/screening-provinces.png`,
  datePickers: `${process.env.REACT_APP_STATIC_BASE_URL}/home/date-pickers.png`,
  totalPremium: `${process.env.REACT_APP_STATIC_BASE_URL}/home/first-level-page/total-premium.png`,
  zbe: `${process.env.REACT_APP_STATIC_BASE_URL}/home/first-level-page/zbe.png`,
  zcbbd: `${process.env.REACT_APP_STATIC_BASE_URL}/home/first-level-page/zcbbd.png`,
  baxm: `${process.env.REACT_APP_STATIC_BASE_URL}/home/first-level-page/baxm.png`,
  sgyffw: `${process.env.REACT_APP_STATIC_BASE_URL}/home/first-level-page/sgyffw.png`,
  bzfufgl: `${process.env.REACT_APP_STATIC_BASE_URL}/home/first-level-page/bzfufgl.png`,
  zbeZbdBg: `${process.env.REACT_APP_STATIC_BASE_URL}/home/first-level-page/zbe-zbd-ba.png`,
  FWFGQKBottom: `${process.env.REACT_APP_STATIC_BASE_URL}/home/first-level-page/FWFGQK-bottom.png`,
  zlpje: `${process.env.REACT_APP_STATIC_BASE_URL}/home/first-level-page/zlpje.png`,
  zlpaj: `${process.env.REACT_APP_STATIC_BASE_URL}/home/first-level-page/zlpaj.png`,
  sgyfZcje: `${process.env.REACT_APP_STATIC_BASE_URL}/home/first-level-page/sgyf-zcje.png`,
  sgyfYzcje: `${process.env.REACT_APP_STATIC_BASE_URL}/home/first-level-page/sgyf-yzcje.png`,
  sgyfSyje: `${process.env.REACT_APP_STATIC_BASE_URL}/home/first-level-page/sgyf-syje.png`,
  addZoom: `${process.env.REACT_APP_STATIC_BASE_URL}/home/first-level-page/add-zoom.png`,
  delZoom: `${process.env.REACT_APP_STATIC_BASE_URL}/home/first-level-page/del-zoom.png`,
  backZoom: `${process.env.REACT_APP_STATIC_BASE_URL}/home/first-level-page/back-zoom.png`,
  clusterMapMarker: `${process.env.REACT_APP_STATIC_BASE_URL}/home/first-level-page/cluster-map-marker.png`,
  mapMarker: `${process.env.REACT_APP_STATIC_BASE_URL}/home/first-level-page/map-marker.png`,
  arrowDown: `${process.env.REACT_APP_STATIC_BASE_URL}/home/first-level-page/arrow-down.png`,
  arrowUp: `${process.env.REACT_APP_STATIC_BASE_URL}/home/first-level-page/arrow-up.png`,
  smile: `${process.env.REACT_APP_STATIC_BASE_URL}/home/second-level-page/smile.png`,
  cry: `${process.env.REACT_APP_STATIC_BASE_URL}/home/second-level-page/cry.png`,
  tableDownload: `${process.env.REACT_APP_STATIC_BASE_URL}/home/second-level-page/table-download.png`,
  navArrowRight: `${process.env.REACT_APP_STATIC_BASE_URL}/region-situation/nav-arrow-right.png`,
  boxTopBorder: `${process.env.REACT_APP_STATIC_BASE_URL}/region-situation/box-top-border.png`,
  大地: `${process.env.REACT_APP_STATIC_BASE_URL}/run-report/大地.jpeg`,
  平安: `${process.env.REACT_APP_STATIC_BASE_URL}/run-report/平安.jpeg`,
  人保: `${process.env.REACT_APP_STATIC_BASE_URL}/run-report/人保.jpeg`,
  人寿: `${process.env.REACT_APP_STATIC_BASE_URL}/run-report/人寿.jpeg`,
  太平洋: `${process.env.REACT_APP_STATIC_BASE_URL}/run-report/太平洋.jpeg`,
  永安: `${process.env.REACT_APP_STATIC_BASE_URL}/run-report/永安.jpeg`,
  主管: `${process.env.REACT_APP_STATIC_BASE_URL}/run-report/主管.jpeg`,
  download5x: `${process.env.REACT_APP_STATIC_BASE_URL}/run-report/download5x.png`,
  fileFolder: `${process.env.REACT_APP_STATIC_BASE_URL}/data-upload/fileFolderSvg.png`,
  file: `${process.env.REACT_APP_STATIC_BASE_URL}/data-upload/fileSvg.png`,
  right: `${process.env.REACT_APP_STATIC_BASE_URL}/data-upload/rightSvg.png`,
  wrong: `${process.env.REACT_APP_STATIC_BASE_URL}/data-upload/wrongSvg.png`,
};

// 统计分析一级表统计口径
export const STATISTICAL_CALIBER = {
  YJWT_BGQ: '统计口径：历年所有保单，排除退保、注销',
  YJWT_BD: '统计口径：起保日期在统计区间内的保单，排除退保、注销',
  SGYF_BGQ: '统计口径：历史所有保单在当前统计区间范围内的统计情况，排除退保、注销的保单',
  SGYF_BD: '统计口径：起保日期在统计区间内的保单，排除退保、注销',
  TBQK_BGQ: '统计口径：所有保单（除退保与注销）',
  TBQK_BD: '统计口径：起保日期在当前统计区间内的保单，排除退保、注销保单',
  LP_BGQ: '统计口径：出险时间在统计区间内的理赔案件，排除注销、拒赔',
  LP_BD: '统计口径：起保日期在当前统计区间内的保单，其中理赔案件排除注销、拒赔',
  SB_BGQ: '统计口径：申报日期在统计区间内的项目，排除退保、注销的保单',
};
