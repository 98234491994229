/*
 * @Description:
 * @Author: chenz-l
 * @Date: 2024-04-25 15:44:19
 * @LastEditors: chenzhen
 */
import React from 'react';
import { AUTHORITY_LIST } from 'utils/enumList';

const defaultMenuConfig = {
  [AUTHORITY_LIST.SXSQK]: {
    text: '陕西省情况',
    key: 'homePage',
  },
  [AUTHORITY_LIST.GDSQK]: {
    text: '各地市情况',
    key: 'regionSituation',
  },
  [AUTHORITY_LIST.BXJGGL]: {
    text: '保险机构概览',
    key: 'insuranceOverview',
  },
  [AUTHORITY_LIST.TJFX]: {
    text: '统计分析',
    key: 'statisticalAnalysis',
    children: {
      [AUTHORITY_LIST.TJFX_HZTJB]: { text: '安责险汇总统计表', key: 'summaryStatisticsTable' },
      [AUTHORITY_LIST.TJFX_BDMXB]: { text: '保单明细表', key: 'policyDetailsTable' },
      [AUTHORITY_LIST.TJFX_LPMXB]: { text: '理赔明细表', key: 'claimsSettlementTable' },
      [AUTHORITY_LIST.TJFX_SGYFMXB]: { text: '事故预防服务明细表', key: 'accidentPreventionTable' },
      [AUTHORITY_LIST.TJFX_AQFWJG]: { text: '安全服务机构', key: 'securityServiceAgencies' },
      [AUTHORITY_LIST.TJFX_SBMXB]: { text: '申报明细表', key: 'declarationDetailsTable' },
    },
  },
  [AUTHORITY_LIST.YXBG]: {
    text: '运行报告',
    key: 'runReport',
  },
  [AUTHORITY_LIST.PYQY]: {
    text: '评优企业',
    key: 'excellentEnterprise',
  },
  [AUTHORITY_LIST.ZLSC]: {
    text: '资料上传',
    key: 'dataUpload',
  },
  [AUTHORITY_LIST.HJWL]: {
    text: '函件往来',
    key: 'correspondenceExchange',
  },
  [AUTHORITY_LIST.BA]: {
    text: '备案',
    key: 'filings',
  },
  [AUTHORITY_LIST.ADMIN_GLYYM]: {
    text: '管理员页面',
    key: 'administrator',
    children: {
      [AUTHORITY_LIST.ADMIN_JSGL]: { text: '角色管理', key: 'rolemanagement' },
      [AUTHORITY_LIST.ADMIN_YHGL]: { text: '用户管理', key: 'usermanagement' },
    },
  },
};
export const defaultMenuList = [
  {
    label: '陕西省情况',
    key: 'homePage',
  },
  {
    label: '各地市情况',
    key: 'regionSituation',
  },
  {
    label: '统计分析',
    key: 'statisticalAnalysis',
  },
  {
    label: '管理员页面',
    key: 'administrator',
  },
];

const forAuthList = (list, cacheList, menuConfig) => {
  if (!list.length) return;
  Object.keys(menuConfig).forEach(item => {
    list.includes(item) && cacheList.push(menuConfig?.[item]);
  });
};
/**
 * ## 根据权限获取菜单列表
 * @param {*} authList
 * @returns
 */
export const getMenuList = authList => {
  if (!authList.length) return [];
  // if (!authList.length) return defaultMenuList;
  const menuList = [];
  forAuthList(authList, menuList, defaultMenuConfig);
  return menuList;
};
