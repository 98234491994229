/*
 * @Description: 文件预览
 * @Author: chenzhen
 * @Date: 2024-09-26 13:52:49
 * @LastEditors: chenzhen
 */
import React from 'react';
import Month from './month';
import Year from './year';

const FilePreview = ({ type, downloadDate }: any) => {
  const queryString = new URLSearchParams(window.location.search);
  const key = queryString.get('key');
  const date = queryString.get('date');

  return (
    <div className="file-container ">
      <div className="width800" id="content-to-export">
        {(key === 'month' || type === 'month' || key === 'halfYear' || type === 'halfYear') && (
          <Month typeKey={type || key} date={date || downloadDate} />
        )}
        {(key === 'year' ||
          type === 'year' ||
          key === 'historicalYear' ||
          type === 'historicalYear') && <Year typeKey={type || key} date={date || downloadDate} />}
      </div>
    </div>
  );
};

export default FilePreview;
