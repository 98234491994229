/*
 * @Description:
 * @Author: chenzhen
 * @Date: 2024-10-18 17:14:58
 * @LastEditors: chenzhen
 */

/**
 * @description: 是否禁用保险公司全局筛选框
 * @return {*}
 */
export const isDisabledInsuranceList = {
  homePage: false,
  regionSituation: false,
  insuranceOverview: true,
  statisticalAnalysis: true,
  runReport: true,
  excellentEnterprise: true,
  dataUpload: true,
  administrator: true,
  changeUserInfo: true,
  filings: true,
  correspondenceExchange: true,
};

/**
 * @description: 是否显示保险公司全局筛选框
 * @return {*}
 */
export const isDisplayInsuranceList = {
  homePage: true,
  regionSituation: true,
  insuranceOverview: true,
  statisticalAnalysis: false,
  runReport: false,
  excellentEnterprise: false,
  dataUpload: false,
  administrator: false,
  changeUserInfo: false,
  filings: false,
  correspondenceExchange: false,
};

/**
 * @description: 是否禁用地区全局筛选框
 * @return {*}
 */
export const isDisabledRegionList = {
  homePage: false,
  regionSituation: false,
  insuranceOverview: false,
  statisticalAnalysis: true,
  runReport: true,
  excellentEnterprise: true,
  dataUpload: false,
  administrator: true,
  changeUserInfo: true,
  filings: true,
  correspondenceExchange: true,
};

/**
 * @description: 是否显示地区全局筛选框
 * @return {*}
 */
export const isDisplayRegionList = {
  homePage: true,
  regionSituation: true,
  insuranceOverview: true,
  statisticalAnalysis: false,
  runReport: false,
  excellentEnterprise: false,
  dataUpload: false,
  administrator: false,
  changeUserInfo: false,
  filings: false,
  correspondenceExchange: false,
};

/**
 * @description: 是否禁用日期全局筛选框
 * @return {*}
 */
export const isDisabledYearsList = {
  homePage: false,
  regionSituation: false,
  insuranceOverview: false,
  statisticalAnalysis: false,
  runReport: true,
  excellentEnterprise: false,
  dataUpload: false,
  administrator: true,
  changeUserInfo: true,
  filings: true,
  correspondenceExchange: true,
};

/**
 * @description: 是否显示日期全局筛选框
 * @return {*}
 */
export const isDisplayYearsList = {
  homePage: true,
  regionSituation: true,
  insuranceOverview: true,
  statisticalAnalysis: false,
  runReport: false,
  excellentEnterprise: false,
  dataUpload: false,
  administrator: false,
  changeUserInfo: false,
  filings: false,
  correspondenceExchange: false,
};
