/*
 * @Description: 函件往来
 * @Author: chenzhen
 * @Date: 2024-09-27 15:46:05
 * @LastEditors: chenzhen
 */
import { axiosSubmit } from 'api/axios';

/**
 * ## 月度报告数据查询
 * @param {*} params
 * @returns
 */
export const getMonthCorrespondence = params =>
  axiosSubmit.get(`/biz/correspondence/month`, { params });

/**
 * ## 半年度报告数据查询
 * @param {*} params
 * @returns
 */
export const getHalfYearCorrespondence = params =>
  axiosSubmit.get(`/biz/correspondence/half_year`, { params });

/**
 * ## 年度报告数据查询
 * @param {*} params
 * @returns
 */
export const getYearCorrespondence = params =>
  axiosSubmit.get(`/biz/correspondence/year`, { params });

/**
 * ## 历年报告数据查询
 * @param {*} params
 * @returns
 */
export const getHisYearCorrespondence = () => axiosSubmit.get(`/biz/correspondence/his_year`);
