/*
 * @Description: 年度
 * @Author: chenzhen
 * @Date: 2024-09-26 14:30:07
 * @LastEditors: chenzhen
 */
import React, { useEffect, useState } from 'react';
import { getYearCorrespondence, getHisYearCorrespondence } from 'api/correspondenceExchangeApi';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import { DateTypes } from '../types';
import { formatValue } from 'utils/index';

const Year = ({ typeKey, date }: { typeKey: string; date }) => {
  const [dataList, setDataList] = useState<any>();
  const [currentDate, setCurrentDate] = useState<DateTypes>({ year: '', month: '', day: '' });
  const [endDate, setEndDate] = useState<DateTypes>({ year: '', month: '', day: '' });

  useEffect(() => {
    getDataList();
  }, [typeKey, date]);

  const getDataList = () => {
    if (typeKey === 'year') {
      getYearCorrespondence({ year: date }).then(res => {
        const { code, data } = res.data;
        if (code === 2000) {
          setDataList(data);
          setEndDate({
            year: dayjs(data?.end_date).year(),
            month: dayjs(data?.end_date).month() + 1,
            day: dayjs(data?.end_date).date(),
          });
        }
      });
    } else if (typeKey === 'historicalYear') {
      getHisYearCorrespondence().then(res => {
        const { code, data } = res.data;
        if (code === 2000) {
          setDataList(data);
          setEndDate({
            year: dayjs(data?.end_date).year(),
            month: dayjs(data?.end_date).month() + 1,
            day: dayjs(data?.end_date).date(),
          });
        }
      });
    }
  };

  return (
    <div>
      <div className="file-title">建筑施工安责险开展情况</div>
      <div className="content">
        <div className="content-subtitle">一、安责险工作整体完成情况</div>
        <div className="content-text">
          2020年7月起，我厅印发《陕西省建筑施工安全生产责任保险实施方案》，按招标程序确定了中国人民财产保险股份有限公司、中国人寿财产保险股份有限公司、中国平安财产保险股份有限公司、中国太平洋财产保险股份有限公司、中国大地财产保险股份有限公司、永安财产保险股份有限公司陕西分公司等6家保险公司为陕西省建筑施工安责险承保机构。从2020年9月1日起，在全省建筑施工行业强制实施安责险，覆盖建筑施工行业全体从业人员。
        </div>
        <div className="content-text">
          {typeKey === 'year'
            ? `自${formatValue(endDate?.year)}年1月1日至${formatValue(
                endDate?.year
              )}年${formatValue(endDate?.month)}月${formatValue(endDate?.day)}日`
            : null}
          {typeKey === 'historicalYear'
            ? `截止${formatValue(endDate?.year)}年${formatValue(endDate?.month)}月${formatValue(
                endDate?.day
              )}日`
            : null}
          ，全省累计承保建筑施工安责险保单件数{formatValue(dataList?.ljcbjzsgazx)}
          件，累计实现承保保费
          {formatValue(dataList?.ljsxcbbf)}
          元，全省累计发生赔案{formatValue(dataList?.qsljfslpa)}笔，累计赔款金额
          {formatValue(dataList?.ljpkje)}
          元。
        </div>
        <div className="content-text">
          提取不低于总额20%的保费建立事故预防服务基金，用于协助投保单位开展事故预防、风险防控、安全生产标准化建设等工作。
          {typeKey === 'year'
            ? `自${formatValue(endDate?.year)}年1月1日至${formatValue(
                endDate?.year
              )}年${formatValue(endDate?.month)}月${formatValue(endDate?.day)}日`
            : null}
          {typeKey === 'historicalYear'
            ? `从2020年9月1日至${formatValue(endDate?.year)}年${formatValue(
                endDate?.month
              )}月${formatValue(endDate?.day)}日`
            : null}
          ，各保险机构为全省建筑施工企业、项目提供安全教育培训{formatValue(dataList?.aqjypx)}
          场、应急演练
          {formatValue(dataList?.yjyl)}场、风险辨识服务{formatValue(dataList?.fxbsfw)}
          次，安全隐患排查
          {formatValue(dataList?.aqyhpc)}次。
        </div>
        <div className="content-subtitle">二、主要工作开展情况</div>
        <div className="content-text">
          <span className="bold">一是规范安责险运行。</span>
          联合中国银保监陕西监管局印发《关于进一步规范建筑施工安全生产责任保险工作的通知》（陕建质发〔2022〕11号），提出了15条具体的措施，要求建设单位、施工单位和行业主管部门严格执行建筑施工安责险有关规定，扎实做好安责险运营工作。
        </div>
        <div className="content-text">
          <span className="bold">二是规范事故预防技术服务。</span>
          印发了《陕西省建筑施工安全生产责任保险事故预防技术服务指导意见》（陕建质发〔2022〕10号），从管理机制、服务内容、服务流程、档案管理、信息系统运用、服务评估、经费使用管理等7个方面，提出了37条指导意见。
        </div>
        <div className="content-text">
          <span className="bold">三是组织开展工作督导。</span>
          采取资料查看、抽查核查和集中反馈情况的方式，对参与安责险保险机构运行情况、预防服务开展情况（包括预防服务费的使用情况）进行专项了2次督导，下发通报督促整改。
        </div>
        <div className="content-text">
          <span className="bold">四是积极推动安责险服务平台建设。</span>
          按照国家《安全生产责任保险事故预防技术服务规范》等文件要求，指导保险机构统一数据管理，共建安责险信息管理平台。
        </div>
        <div className="content-subtitle">三、存在问题及困难</div>
        <div className="content-text">
          一是市场运行不规范。保险机构存在恶意压低保费问题，保险机构承保、理赔、事故预防服务方面运营流程和规范标准不统一，部分保险机构前期承诺客户内容后续无法兑现，致使客户无法获得优质的保险服务。二是事故预防服务落实不到位。预防服务工作推进缓慢，第三方机构运行管理不规范，对预防服务的理解有偏差，预防服务工作效果不够好。
        </div>
        <div className="content-subtitle">四、下一步工作</div>
        <div className="content-text">
          加强安责险运行管理，利用监管平台实时监管安责险运行与预防技术服务情况，及时进行预警处置。研究制定第三方服务机构基本服务标准，进一步规范市场行为，充分发挥安责险预防服务作用。
        </div>
      </div>
    </div>
  );
};

export default Year;
