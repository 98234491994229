/*
 * @Description:
 * @Author: chenz-l
 * @Date: 2024-05-16 14:28:38
 * @LastEditors: chenzhen
 */

import React, { FC, useState, useMemo, useEffect, useRef } from 'react';
import { DatePicker, Spin } from 'antd';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import debounce from 'debounce-promise';
import { setFilterTime } from 'store/action/dataFilter';
import { IMAGE_LIST } from 'utils/enumList';
import { useSelector } from 'react-redux';
import type { GetProps } from 'antd';
import { StateTypes, DataFilterTypes } from 'store/types';
import { isDisabledYearsList, isDisplayYearsList } from './dataList';
type RangePickerProps = GetProps<typeof DatePicker.RangePicker>;

const YearsFilter = ({ menuKey }: { menuKey: string }) => {
  const dispatch = useDispatch();
  const [isYearsDisabled, setIsYearsDisabled] = useState<boolean>(false);
  const [isShowYears, setIsShowYears] = useState<boolean>(false);
  const dataFilter: DataFilterTypes = useSelector((state: StateTypes) => state.dataFilter);

  const datePickerStartRef = useRef<HTMLDivElement>(null);
  const datePickerEndRef = useRef<HTMLDivElement>(null);

  const endTime = moment()?.format('YYYY-MM-DD');
  const startTime = moment().startOf('year')?.format('YYYY-MM-DD');

  useEffect(() => {
    setIsShowYears(isDisplayYearsList?.[menuKey]);
    setIsYearsDisabled(isDisabledYearsList?.[menuKey]);
  }, [menuKey]);

  useEffect(() => {
    dispatch(setFilterTime([startTime, endTime]));
  }, []);

  // 选择开始日期
  const handleChangeStartYear = useMemo(
    () =>
      debounce((val: dayjs.Dayjs) => {
        const time = [`${val?.format('YYYY-MM-DD')}`, `${dataFilter?.filter_time[1]}`];
        dispatch(setFilterTime(time));
      }, 300),
    [dataFilter]
  );

  // 选择截止日期
  const handleChangeEndYear = useMemo(
    () =>
      debounce((val: dayjs.Dayjs) => {
        const time = [`${dataFilter?.filter_time[0]}`, `${val?.format('YYYY-MM-DD')}`];
        dispatch(setFilterTime(time));
      }, 300),
    [dataFilter]
  );

  // 禁用2020-09-01之前的日期
  const disabledStartDate: RangePickerProps['disabledDate'] = (current, info) => {
    if (info.type === 'year' && current.year() === 2020) {
      return false;
    }
    return current && current < dayjs('2020-08-31').endOf('day');
  };

  // 禁用截止日期之前的日期
  const disabledEndDate: RangePickerProps['disabledDate'] = (current, info) => {
    if (
      info.type === 'year' &&
      current.year() === new Date(dataFilter?.filter_time[0]).getFullYear()
    ) {
      return false;
    }
    return current && current < dayjs(dataFilter?.filter_time[0]).endOf('day');
  };

  return (
    <div
      className="years-filter"
      style={{
        backgroundImage: `url(${IMAGE_LIST.datePickers})`,
        display: isShowYears ? '' : 'none',
      }}
    >
      <div className="years-filter-time-picker" ref={datePickerStartRef}>
        <DatePicker
          className="global-time-picker years-filter-content"
          popupClassName="global-time-picker-popup"
          onChange={dates => handleChangeStartYear(dates)}
          defaultValue={dayjs(startTime)}
          suffixIcon={<></>}
          format="YYYY-MM-DD"
          disabled={isYearsDisabled}
          allowClear={false}
          disabledDate={disabledStartDate}
        />
      </div>
      <div className="years-filter-time-picker" ref={datePickerEndRef}>
        <DatePicker
          className="global-time-picker years-filter-content"
          popupClassName="global-time-picker-popup"
          onChange={dates => handleChangeEndYear(dates)}
          defaultValue={dayjs(endTime)}
          format="YYYY-MM-DD"
          disabled={isYearsDisabled}
          allowClear={false}
          disabledDate={disabledEndDate}
        />
      </div>
    </div>
  );
};

export default YearsFilter;
