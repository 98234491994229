/*
 * @Description: 月度
 * @Author: chenzhen
 * @Date: 2024-09-26 14:29:06
 * @LastEditors: chenzhen
 */
import React, { useEffect, useState } from 'react';
import { getMonthCorrespondence, getHalfYearCorrespondence } from 'api/correspondenceExchangeApi';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import { DateTypes } from '../types';
import { formatValue } from 'utils/index';

import './index.scss';

const Month = ({ typeKey, date }: { typeKey: string; date }) => {
  const [dataList, setDataList] = useState<any>();
  const [currentDate, setCurrentDate] = useState<DateTypes>({ year: '', month: '', day: '' });
  const [endDate, setEndDate] = useState<DateTypes>({ year: '', month: '', day: '' });

  useEffect(() => {
    getDataList();
  }, [typeKey, date]);

  const getDataList = async () => {
    if (typeKey === 'month') {
      await getMonthCorrespondence({ yearMonth: date }).then(res => {
        const { code, data } = res.data;
        if (code === 2000) {
          setDataList(data);
          setCurrentDate({
            year: dayjs(data?.current_date).year(),
            month: dayjs(data?.current_date).month() + 1,
            day: dayjs(data?.current_date).date(),
          });
          setEndDate({
            year: dayjs(data?.month).year(),
            month: dayjs(data?.month).month() + 1,
          });
        }
      });
    } else if (typeKey === 'halfYear') {
      const endDate = date.split(',')?.[1];
      await getHalfYearCorrespondence({ endDate }).then(res => {
        const { code, data } = res.data;
        if (code === 2000) {
          setDataList(data);
          setCurrentDate({
            year: dayjs(data?.current_date).year(),
            month: dayjs(data?.current_date).month() + 1,
            day: dayjs(data?.current_date).date(),
          });
          setEndDate({
            year: dayjs(data?.end_date).year(),
            month: dayjs(data?.end_date).month() + 1,
            day: dayjs(data?.end_date).date(),
          });
        }
      });
    }
  };
  return (
    <div>
      <div className="file-title">
        关于{formatValue(endDate?.year) || '--'}年
        {typeKey === 'month' ? formatValue(endDate?.month) + '月' : null}
        {typeKey === 'halfYear' ? '上半年' : null}建筑施工安全生产
        <br />
        责任保险工作开展情况的函
      </div>
      <div className="content">
        <div className="content-text">
          现将{formatValue(endDate?.year)}年
          {typeKey === 'month' ? formatValue(endDate?.month) + '月' : null}
          {typeKey === 'halfYear' ? '上半年' : null}建筑施工安全生产责任保险工作开展情况函告如下。
        </div>
        <div className="content-subtitle">一、投保情况</div>
        <div className="content-text">
          {typeKey === 'halfYear' &&
            `自2020年9月1日起，全省累计投保建筑施工安责险${formatValue(
              dataList?.aqsljtbazx
            )}单，尚未完工项目${formatValue(dataList?.awwgxm)}个，实现含税签单保费${formatValue(
              dataList?.ahsqdbf
            )}亿元。`}
          {typeKey === 'month'
            ? `${formatValue(endDate?.year)}年${formatValue(endDate?.month)}月`
            : null}
          {typeKey === 'halfYear'
            ? `${formatValue(endDate?.year)}年1月1日至${formatValue(endDate?.month)}月${formatValue(
                endDate?.day
              )}日`
            : null}
          ，全省累计投保建筑施工安责险{formatValue(dataList?.qsljtbazx)}单，
          {typeKey === 'halfYear' && `尚未完工项目${formatValue(dataList?.wwgxm)}个，`}
          实现含税签单保费
          {formatValue(dataList?.hsqdbf)}
          {typeKey === 'month' ? '万' : '亿'}元。其中西安投保体量占全省的
          {formatValue(dataList?.xatbtlzb)}。
        </div>
        <div className="content-subtitle">二、理赔情况</div>
        <div className="content-text">
          {formatValue(endDate?.year)}年
          {typeKey === 'month' ? formatValue(endDate?.month) + '月' : null}
          {typeKey === 'halfYear' ? '上半年' : null}
          ，全省累计发生理赔案件{formatValue(dataList?.qsljfslpajs)}
          件，应理赔金额{formatValue(dataList?.ylpje)}万元，其中已决金额
          {formatValue(dataList?.yjje)}
          万元。事故年度赔付率
          {formatValue(dataList?.sgndpfl)}，平均结案周期
          {formatValue(dataList?.pjjazq)}
          天，有效的支撑了施工项目在遭遇安全生产事故时的快速复工复产。
        </div>
        <div className="content-subtitle">三、事故预防服务情况</div>
        <div className="content-text">
          {formatValue(endDate?.year)}年
          {typeKey === 'month' ? formatValue(endDate?.month) + '月' : null}
          {typeKey === 'halfYear' ? '上半年' : null}
          ，全省累计支出事故预防服务资金{formatValue(dataList?.qsljzcsgyffwzj)}
          万元，累计实现服务派单{formatValue(dataList?.ljsxfwpd)}
          单，完成生产隐患排查和风险辨识累计{formatValue(dataList?.wcscyhpchfxbsljcs)}
          次，排查隐患总数
          {formatValue(dataList?.yhpczs)}
          个，为施工项目提供安全教育培训和应急演练{formatValue(dataList?.tgaqjypxhyjylcs)}
          场次，通过事故预防服务工作的深入落实，为全省安全生产和全辖风险减量奠定了坚实的基础。
        </div>
        <div className="content-text">附件：安全生产责任保险工作台账 </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <div className="footer">
        <div className="content-text">陕西省住房和城乡建设厅</div>
        <div className="content-text">
          {formatValue(currentDate?.year)}年{formatValue(currentDate?.month)}月
          {formatValue(currentDate?.day)}日
        </div>
      </div>
      <div className="file-title">安全生产责任保险工作台账</div>
      <div className="teble-box">
        <table>
          <tr>
            <th>内容</th>
            <th className="borderleft">建筑施工</th>
          </tr>
          <tr>
            <td>应投保企业数</td>
            <td className="borderleft"></td>
          </tr>
          <tr>
            <td>已投保企业数</td>
            <td className="borderleft">{formatValue(dataList?.ytbqys)}</td>
          </tr>
          <tr>
            <td>收取保费（元）</td>
            <td className="borderleft">{formatValue(dataList?.sqbf)}</td>
          </tr>
          <tr>
            <td>提取事故预防费（元）</td>
            <td className="borderleft">{formatValue(dataList?.tqsgyff)}</td>
          </tr>
          <tr>
            <td>已支出事故预防费（元）</td>
            <td className="borderleft">{formatValue(dataList?.yzcsgyff)}</td>
          </tr>
          <tr>
            <td>事故预防费支持率（%）</td>
            <td className="borderleft">{formatValue(dataList?.sgyffzcl)}</td>
          </tr>
          <tr>
            <td>完成事故预防服务（项）</td>
            <td className="borderleft">{formatValue(dataList?.wcsgyffwx)}</td>
          </tr>
          <tr>
            <td>正在开展事故预防服务（项）</td>
            <td className="borderleft">{formatValue(dataList?.zzkzsgyffwx)}</td>
          </tr>
          <tr>
            <td>未开展事故预防服务（项）</td>
            <td className="borderleft">{formatValue(dataList?.wkzsgyffwx)}</td>
          </tr>
          <tr>
            <td>事故预防服务完成率（%）</td>
            <td className="borderleft">{formatValue(dataList?.sgyffwwcl)}</td>
          </tr>
        </table>
      </div>
    </div>
  );
};

export default Month;
